import { createContext, useContext } from "react";
import { runInAction, makeAutoObservable } from "mobx";
import {
  Category,
  Coordinate,
  Event,
  Organization,
} from "@root/utils/entities";
import {
  DateRange,
  FilterType,
  MapBounds,
  NumberRange,
} from "@root/utils/types";
import { eventService } from "@root/services/api/eventService";
import { getApiError, hasErrors } from "@root/utils/functions";
import { ModalStore } from "./modalStore";
import { EventSearchDefaults } from "@root/utils/enums";
import { PaginationStore } from "./paginationStore";

type ViewMode = "list" | "map";
export type EventSearchFilters = {
  address?: string;
  mileRadius: number;
  ageRange: NumberRange;
  dateRange?: DateRange;
  categories?: string[];
  coordinate?: Coordinate;
};

export class EventPageStore {
  private readonly _defaultFilters: EventSearchFilters = {
    address: "",
    mileRadius: EventSearchDefaults.MileRadius,
    ageRange: {
      min: EventSearchDefaults.MinAge,
      max: EventSearchDefaults.MaxAge,
    },
  };

  readonly paginationStore;
  readonly viewModalStore;
  readonly orgModalStore;

  isLoading: boolean = false;
  fitMap: boolean = false;
  zoomLevel?: number;
  error?: string;
  categories: Category[];
  events?: Event[];
  filters: EventSearchFilters;
  mapBounds?: MapBounds;
  viewMode: ViewMode = "map";
  event?: Event;
  organization?: Organization;
  currentSubview?: "event" | "organization";

  constructor() {
    makeAutoObservable(this);
    this.filters = this._defaultFilters;
    this.categories = [];
    this.events = [];
    this.paginationStore = new PaginationStore();
    this.viewModalStore = new ModalStore();
    this.orgModalStore = new ModalStore();
  }

  showLoading() {
    this.isLoading = true;
  }

  hideLoading() {
    this.isLoading = false;
  }

  showError(error?: string) {
    this.error = error;
    this.isLoading = false;
  }

  searchEvents(
    userLocation?: Coordinate,
    fitMap: boolean = false,
    zoomLevel?: number
  ) {
    eventService
      .searchEvents(
        this.paginationStore.page,
        this.filters.address,
        this.filters.mileRadius,
        this.filters.ageRange,
        this.filters.dateRange,
        this.filters.categories,
        this.mapBounds,
        userLocation
      )
      .then((response) =>
        runInAction(() => {
          if (hasErrors(response)) {
            this.error = getApiError(response)?.message;
          } else {
            this.error = undefined;

            const events = response.data?.events ?? [];
            const searchFilters = response.data?.searchFilters;
            const pagination = response.data?.pagination;

            this.events = events;
            this.filters.coordinate = searchFilters?.coordinate;

            this.fitMap = fitMap;
            this.zoomLevel = zoomLevel;
            this.paginationStore.setPagination(
              pagination?.page ?? 0,
              pagination?.pageCount ?? 0,
              pagination?.totalCount ?? 0
            );
          }
        })
      );
  }

  loadCategories() {
    eventService.getCategories().then((response) =>
      runInAction(() => {
        if (hasErrors(response)) {
          this.error = getApiError(response)?.message;
          this.categories = [];
        } else {
          this.categories = response.data?.categories ?? [];
        }
      })
    );
  }

  setFilters(
    filterType: FilterType,
    filters: EventSearchFilters,
    userLocation?: Coordinate,
    search: boolean = false,
    fitMap: boolean = false
  ) {
    this.filters = filters;

    if (filterType === "location") {
      this.mapBounds = undefined;
    }

    if (search) this.searchEvents(userLocation, fitMap);
  }

  setMapBounds(northEast: Coordinate, southWest: Coordinate) {
    if (
      this.mapBounds?.northEast.latitude === northEast.latitude &&
      this.mapBounds?.northEast.longitude === northEast.longitude &&
      this.mapBounds?.southWest.latitude === southWest.latitude &&
      this.mapBounds?.southWest.longitude === southWest.longitude
    )
      return;

    this.mapBounds = { northEast, southWest };
    this.fitMap = false;

    this.searchEvents();
  }

  showList() {
    this.viewMode = "list";
  }

  showMap() {
    this.viewMode = "map";
  }

  toggleViewMode() {
    if (this.viewMode === "map") this.showList();
    else this.showMap();
  }

  toggleFitMap(val: boolean) {
    this.fitMap = val;
    if (this.fitMap) this.mapBounds = undefined;
  }

  showEvent(event: Event) {
    this.event = event;
    this.currentSubview = "event";
    this.viewModalStore.open();
  }

  hideEvent() {
    this.viewModalStore.close();
    this.event = undefined;
    this.currentSubview = this.organization ? "organization" : undefined;
  }

  showOrganization(organization?: Organization) {
    this.organization = organization;
    this.currentSubview = "organization";
    this.orgModalStore?.open();
  }

  hideOrganization() {
    this.orgModalStore?.close();
    this.organization = undefined;
    this.currentSubview = this.event ? "event" : undefined;
  }
}

export const EventPageContext = createContext<EventPageStore>(null!);
export const useEventPageContext = () => useContext(EventPageContext);
