import {
  Business,
  Event,
  NotificationsActiveOutlined,
  Password,
  Person,
} from "@mui/icons-material";
import { MenuRoute } from "./MenuRoute";

export const UserRoutes: MenuRoute[] = [
  { text: "Profile", href: "/account", icon: () => <Person /> },
  { text: "My Events", href: "/account/events", icon: () => <Event /> },
  {
    text: "Organizations",
    href: "/account/organizations",
    icon: () => <Business />,
  },
  {
    text: "Following",
    expandable: true,
    expanded: false,
    icon: () => <NotificationsActiveOutlined />,
    subRoutes: [
      {
        text: "Events",
        href: "/account/events/following",
        icon: () => <Event />,
      },
      {
        text: "Organizations",
        href: "/account/organizations/following",
        icon: () => <Business />,
      },
    ],
  },
  {
    text: "Password",
    href: "/account/change-password",
    icon: () => <Password />,
  },
];
